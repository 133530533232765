<template lang="pug">
  v-container
    v-row(justify="center")
      v-col(cols="12" lg="3" md="3")
        v-card
          v-toolbar(
            color="primary"
            dense
            dark
            flat
          )
            span Create
            template(v-slot:extension)
              v-tabs(
                v-model="tab"
                align-with-title
              )
                v-tab Single
                v-tab Multi
          v-container
            v-tabs-items(v-model="tab")
              v-tab-item
                create-rack(
                  :default-data.sync="editRackEgg"
                  :incubator-rack-list-items="incubatorRackList"
                )
              v-tab-item
                create-many-rack(
                  :incubator-rack-list-items="incubatorRackList"
                )
      v-col(cols="12" lg="3" md="3")
        v-card
          v-toolbar(
            color="primary"
            dense
            dark
            flat
          )
            span List
          v-container
            table-rack(
              @edit="item => editRackEgg = item"
            )
</template>
<script>
import incubatorRepository from '@/repositories/incubator.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [incubatorRackVars, incubatorRackVarNames] = requestVars({ identifier: 'incubator-rack' })
const incubatorRackHandler = new VueRequestHandler(null, incubatorRackVarNames)

export default {
  name: 'RackEgg',
  components: {
    createRack: () => import('./Create'),
    createManyRack: () => import('./CreateMany'),
    tableRack: () => import('./Table'),
  },
  data () {
    return {
      editRackEgg: {},
      tab: 0,
      ...incubatorRackVars,
    }
  },
  computed: {
    editMode () {
      return !this.$objectEmpty(this.editRackEgg)
    },
  },
  watch: {
    editMode (val) {
      if (val) this.tab = 0
    },
  },
  created () {
    this.getRacks()
  },
  methods: {
    getRacks () {
      const handler = incubatorRackHandler
      const repository = incubatorRepository.racks
      const params = { search: this.incubatorRackListSearch }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
  },
}
</script>