import repository from './repository'

const resource = 'incubators'

export default {
  index (params) {
    return repository.get(resource, { params })
  },
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
  racks (params) {
    return repository.get(`${resource}/racks`, { params })
  },
  addRack (id) {
    return repository.post(`${resource}/${id}/racks`)
  },
  exportIncubatorRackEggs (params) {
    const conf = {
      headers: {
        Accept: 'application/xlsx',
      },
      responseType: 'blob',
      params,
    }
    return repository.get(`${resource}/export/incubator-rack-eggs`, conf)
  },
}
